<template>
  <v-layout row wrap fill-height>
    <v-flex sm7 hidden-xs-only class="bleh">
      <v-img
        class="logo"
        v-if="$route.query.type === 'runner'"
        :src="require('@/assets/bolter-logo.svg')"
        :height="142"
        :width="600"
      ></v-img>
      <v-img v-else :src="require('@/assets/login.svg')" height="100vh"></v-img>
    </v-flex>
    <v-flex xs12 sm5 column>
      <v-layout column justify-center align-center fill-height>
        <v-flex xs5 pa-4>
          <router-view />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
export default {};
</script>
<style scoped>
.bleh {
  background-color: #0a43a7;
}
.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 40%;
  margin-top: 35%;
}
</style>
