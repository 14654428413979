import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VLayout,
    { attrs: { row: "", wrap: "", "fill-height": "" } },
    [
      _c(
        VFlex,
        { staticClass: "bleh", attrs: { sm7: "", "hidden-xs-only": "" } },
        [
          _vm.$route.query.type === "runner"
            ? _c(VImg, {
                staticClass: "logo",
                attrs: {
                  src: require("@/assets/bolter-logo.svg"),
                  height: 142,
                  width: 600
                }
              })
            : _c(VImg, {
                attrs: { src: require("@/assets/login.svg"), height: "100vh" }
              })
        ],
        1
      ),
      _c(
        VFlex,
        { attrs: { xs12: "", sm5: "", column: "" } },
        [
          _c(
            VLayout,
            {
              attrs: {
                column: "",
                "justify-center": "",
                "align-center": "",
                "fill-height": ""
              }
            },
            [
              _c(
                VFlex,
                { attrs: { xs5: "", "pa-4": "" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }